@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cardbox{
	@apply w-72 cursor-pointer relative border-b-4 rounded-md bg-gradient-to-t duration-500 delay-100 from-white/20 h-44 text-center px-3 py-7 self-end;
}

 .border-gradient {
    /* border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #9461fd, #ff53cd, #2dd9fe) 1; */
     border: double 2px transparent;
  border-radius: 10px;
  background-image: linear-gradient(white, white), 
                    linear-gradient(to right,  #9461fd, #ff53cd, #2dd9fe);
  background-origin: border-box;
  background-clip: content-box, border-box;
  }

  .border-gradient1 {
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #9461fd, #ff53cd, #2dd9fe) 1;
    
  }

   .border-gradientbottom {
    border-top-width: 4px;
    border-style: solid;
    /* border-image: linear-gradient(to right, #9461fd, #4003e6, #2dd9fe) 1; */
    border-image: linear-gradient(to right, #9461fd, #9461fd, #9461fd) 1;
  }

.cardbox-active{
  @apply h-96
}

.logocard{
	@apply text-5xl mt-2 mx-auto max-w-[5rem]  min-w-[5rem] min-h-[5rem] rounded-full bg-red-200 text-red-600 grid place-items-center ;
}

.cardpara{
  @apply mt-4;
}

.cardbox .skill-level{
 @apply  border-2 border-gray-200 text-white -right-2.5 min-w-[3rem] min-h-[3rem] grid place-items-center rounded-full max-w-[3rem] absolute  scale-0;
}

.cardbox-active .skill-level{
  @apply scale-[1.3] delay-700 duration-300;
}



@layer utilities {
  .custom-shadow {
    box-shadow: 28px 28px 56px 20px rgba(197, 197, 197, 1), -28px -28px 56px rgba(251, 251, 251, 1);
  }
}

@layer utilities {
  .shadow-neumorphic {
    /* box-shadow: 9px 8px 10px #b8b9be, -3px -3px 7px #fff; */
    box-shadow: -3px 3px 20px #b8b9be, -3px -3px 7px #b3a8a8;
  }
}

@layer utilities {
  .shadow-neumorphic1 {
    box-shadow: 5px 4px 7px #fff, -3px -2px 6px #b8b9be
  }
}

@layer utilities {
  .shadow-neumorphicnew {
    box-shadow: inset 9px 8px 7px #b8b9be,inset -3px -3px 7px #fff;
  }
}

.ant-btn-primary {
  color: #000;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  border: 1px solid #c5c9cc;
}

