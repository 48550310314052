/* ==========================Font========================== */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

/* Your custom font-face declaration (if any) should go here */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif; /* Use "Poppins" font as the default */
}



.container1 {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-template-rows: auto;
  /* gap: 20px 20px; */
}

.card1 {
  position: relative;
  max-width: 190px;
  max-height: 250px;
  background: #3b3b3b;
  /* display: flex; */
  justify-content: center;
  align-items: start;
  text-align: start;
  border: 1px solid #000;
  cursor: pointer;
}

.card1:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, .05);
  pointer-events: none;
}

.card1.skew::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: #fff;
  transform: skew(-6deg, 2deg);
  z-index: -1;
}

.card1.skew.glow:nth-child(1)::before {
  background: linear-gradient(315deg, #00bcd4, #72fec3);
  border-radius: 20px;
}

.card1 .content1 {
  position: relative;
  padding: 20px;
  transform: translateY(10px);
}

.card1 .content1 h2 {
  position: absolute;
  top: -9px;
    margin: 0;
    right: 0;
  text-align: end;
  padding: 0;
  font-size: 4em;
  transition: .5s;
  z-index: -1;
}

.card1:hover .content1 h2 {
  top: -20px;
}

.card1 .content1 h3 {
  margin: 0 0 10px;
  padding: 0;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
}

.card1 .content1 p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #fff;
}

.card1 .content1 a {
  margin: 20px 0 0;
  position: relative;
  padding: 10px 20px;
  text-decoration: none;
  border: 1px solid #fff;
  display: inline-block;
  color: #fff;
  transition: .5s;
  transform: translateY(-40px);
  opacity: 0;
  visibility: hidden;
}

.card1:hover .content1 a {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.card1 .content1 a:hover {
  background: #fff;
  color: #000;
}

@keyframes colorChange {
  0% {
    color: #fff;
  }
  50% {
    color: #b1b493;
  }
  80% {
    color: #4f8a8b;
  }
  100% {
    color: #fff;
  }
}

/* =======================Responsive Breakpoint ========================== */
@media only screen and (max-width: 992px) {
  .container1 {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .container1 .card1 {
    width: 250px;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .container1 .card1 {
    max-width: 380px;
    flex-direction: column;
    height: auto;
  }

  .container1 .card1 .imgBox {
    position: relative;
  }

  .container1 .card1 .imgBox,
  .container1 .card1:hover .imgBox {
    width: 100%;
    height: 210px;
    left: 0;
    border-radius: 0;
  }

  .container1 .card1 .imgBox img,
  .container1 .card1:hover .imgBox img {
    max-width: 100px;
  }

  .container1 .card1 .content1 {
    position: relative;
    width: 100%;
  }
}

/* In your style.css or add inline styles */

.container1 {
  margin: 0 -10px; /* Add negative margin to the container */
}

.card1 {
  padding: 0 10px; /* Add padding to each card to create space */
  border-radius: 20px;
}





.container0 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */
    /* min-height: 100vh; */
    
}

.card0 {
    background: #3b3b3b;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    min-width: 300px;
    min-height: 450px;
    margin-top: 0%;
    margin: 30px;
    border-radius: 20px;
    border-bottom-left-radius: 160px;
    border-bottom-right-radius: 160px;
    box-shadow: 0 15px 0 #fff,
            inset 0 -15px 0 rgba(255, 255, 255, 0.24),
            0 45px 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;   
}

.card0::before {
    content: "";
    position: absolute;
    top: -140px;
    left: -40%;
    padding: 0;
    width: 100%;
    height: 120%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2));
    transform: rotate(35deg);
    pointer-events: none;
    filter: blur(5px);
}

.card0:nth-child(1) {
    background: linear-gradient(to bottom, #3b3b3b, #3b3b3b);
}

.card0:nth-child(2) {
    background: linear-gradient(to bottom, #3b3b3b, #3b3b3b);
}

.card0:nth-child(3) {
    background: linear-gradient(to bottom, #3b3b3b, #3b3b3b);
}

.card0 .icon {
    position: relative;
    width: 140px;
    height: 120px;
    background: #fff;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    box-shadow: 0 15px 0 rgba(0, 0, 0, 0.1),
            inset 0 -8px 0 #fff;
    /* z-index: 100; */
    display: flex;
    color: #000;
    justify-content: center;
    align-items: flex-start;
}

.card0 .icon::before {
    content: "";
    position: absolute;
    top: 0;
    left: -50px;
    width: 50px;
    height: 50px;
    background: transparent;
    border-top-right-radius: 50px;
    /* box-shadow: 15px -15px 0 15px #3c2846; */
}

.card0 .icon::after {
    content: "";
    position: absolute;
    top: 0;
    right: -50px;
    width: 50px;
    height: 50px;
    background: transparent;
    border-top-left-radius: 50px;
    /* box-shadow: -15px -15px 0 15px #3c2846; */
}

.card0 .icon ion-icon {
    color: #fff;
    position: relative;
    font-size: 6em;
    z-index: 1000;
    --ionicon-stroke-width: 24px;
}


.card0 .content {
    position: absolute;
    width: 100%;
    padding: 30px;
    padding-top: 150px;
    text-align: center;
}

.card0 .content h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
}

.card0 .content p {
    color: #72fec3;
    line-height: 1.5em;
}



