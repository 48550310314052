@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}
.ant-table-cell {
  background-color: white !important;
}
.ant-table-cell::before {
  background-color: rgb(17, 16, 16) !important;
}
.ant-table-thead {
  border-bottom: 2px solid gray !important;
}
.ant-table-wrapper .ant-table-container table > thead > tr:first-child > * {
  border-bottom: 2px solid #e7e7e7 !important;
}

.ant-drawer .ant-drawer-content{
  width: 80%;
  height: 100%;
  margin:  0 0 0 auto;
  /* margin-left: 10%; */
}

.mobiletab{
  /* width: auto; */
  width: fit-content;
}

.icon {
  transition: transform 0.2s ease-in-out;
}

.icon:hover {
  transform: scale(1.1);
}

.ant-carousel .slick-initialized .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* page styles */
.ax-hidden {
  visibility: hidden;
  position: absolute;
}

/* carousel styles */
.carousel {
  --carousel-height: 550px;
  --carousel-width: 2000px;
  --carousel-item-height: 250px;
  --carousel-item-width: 150px;
  width: 100%;
}

.carousel-container {
  align-items: center;
  display: flex;
  min-height: var(--carousel-height);
  margin: 0 auto;
  max-width: var(--carousel-width);
  position: relative;
}

.carousel-item {
  height: var(--carousel-item-height);
  opacity: 0;
  position: absolute;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
  width: var(--carousel-item-width);
  z-index: 0;
}

/* .carousel-item {
  position: relative;
} */

.carousel-item-overlay {
  position: absolute;
 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; 
}



.carousel-item-overlay > * {
  margin-bottom: 10px; 
}

.otpInput {
  width: 100%;
  border: 1px solid #ccc;
}


.carousel-item-1 {
  left: 15%;
  opacity: 0.4;
}

.carousel-item-2,
.carousel-item-4 {
  height: calc(var(--carousel-item-height) * 1.5);
  opacity: 1;
  
  width: calc(var(--carousel-item-width) * 1.5);
  z-index: 1;
}

.carousel-item-2 {
  left: 30%;
}

.carousel-item-3 {
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  height: calc(var(--carousel-item-height) * 2);
  opacity: 1;
  left: 50%;
  width: calc(var(--carousel-item-width) * 2);
  z-index: 2;
}

.carousel-item-4 {
  left: 70%;
}

.carousel-item-5 {
  left: 85%;
  opacity: 0.4;
}

.carousel-controls {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

/* carousel button styles */
.carousel-control {  
  background-color: transparent;
  border: 2px solid;
  border-radius: 4px;
  color: #9461fd;
  cursor: pointer;
  height: 22px;
  margin: 0 20px;
  position: relative;
  transform: scale(1.5);
  transition: transform 0.5s ease-out;
  width: 22px;
}

.carousel-control:hover {
  transform: scale(1.3);
}

/* previous button */
.carousel-control-previous::after,
.carousel-control-previous::before {
  box-sizing: border-box; 
  content: '';
  display: block;
  height: 8px;
  position: absolute;
  top: 5px
}
.carousel-control-previous::before {
  background: currentColor;
  border-radius: 2px;
  right: 11px;
  width: 2px;
}
.carousel-control-previous::after {
  border-bottom: 4px solid transparent;
  border-right: 5px solid;
  border-top: 4px solid transparent;
  right: 5px;
  width: 0;
}

/* next button */
.carousel-control-next::after,
.carousel-control-next::before {
  box-sizing: border-box;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  top: 5px
}
.carousel-control-next::before {
  background: currentColor;
  border-radius: 2px;
  left: 11px;
  width: 2px;
}
.carousel-control-next::after {
  border-bottom: 4px solid transparent;
  border-left: 5px solid;
  border-top: 4px solid transparent;
  left: 5px;
  width: 0;
}

/* play button */
.carousel-control-play::before {
  border-bottom: 5px solid transparent;
  border-left: 6px solid;
  border-top: 5px solid transparent;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  left: 7px;
  top: 4px;
  width: 0;
}

/* pause button */
.carousel-control-play.playing::before {
  border-bottom: 0;
  border-left: 2px solid;
  border-right: 2px solid;
  border-top: 0;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 6px;
  position: absolute;
  left: 6px;
  top: 6px; 
  width: 6px;
}

/* add button */
.carousel-control-add::after,
.carousel-control-add::before {
  background: currentColor;
  border-radius: 5px;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  left: 4px;
  top: 8px;
  width: 10px;
}
.carousel-control-add::after {
  height: 10px;
  left: 8px;
  top: 4px;
  width: 2px;
}

/* MatrixCard.css */
.image-top {
  position: absolute;
  
  margin: auto; 
}

.ant-table-wrapper .ant-table {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 13px !important;
    line-height: 1.5714285714285714;
    list-style: none;
}


/* MatrixCard.css */
.matrix-card {
  position: relative;
  width: 100%;
  height: 320px;
  perspective: 1000px; 
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s; 
  transform-style: preserve-3d; 
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.front {
  background-color: #fff; 
}

.back {
  /* background-color: #ddd;  */
  transform: rotateY(180deg); 
}

.matrix-card:hover .card-inner {
  transform: rotateY(180deg); 
}

.matrix-details {
  position: absolute;
  width: 100%;
  
  color: white;
}

.matrix-level {
  font-weight: bold;
  margin-top: 10px;
}

.level-percent {
  color: white;
  padding: 20px;
}

.level-header {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.level-info {
  font-size: 14px;
}

/* .tab {
  transition: all 0.s ease; 
} */

.container {
  padding: 10px;
}

.ant-checkbox-checked .ant-checkbox-inner {
 background-color: #9461fd;
    border-color: #9461fd;
}

.ant-checkbox .ant-checkbox-inner {
    box-sizing: border-box;
    position: relative;
    top: 0;
    inset-inline-start: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    border: 1px solid 
  }


.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
.container {
  font-family: "Lato", sans-serif;
  /* width: 100vh; */
  margin: 0 auto;
}

.outletcontaonner {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 45px;
}

.wrapper {
  display: block;
  /* height: 400px; */
  vertical-align: middle;
}



.nav {
  margin-top: 40px;
}
.pull-right {
  float: right;
}
/* a, a:active {
	color: #212121;
	text-decoration: none;
}
a:hover {
	color: #999;
} */
.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #000;
  width: 250px;
  box-shadow: 1px 2px 3px 0px #181414;

  cursor: default;
  margin: 7px 3px;
  padding: 10px 10px 10px 30px;
  min-width: 180px;
  float: left;
  position: relative;

  background-color: #f5f5f5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}
.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -16px;
  width: 0;
  height: 0;

  border-top: 19px solid transparent;
  border-bottom: 23px solid transparent;
  border-left: 18px solid #f5f5f5;
  z-index: 2;
  transition: border-color 0.2s ease;
}
.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #edebf2;
  z-index: 0;
  
}
.arrow-steps .step:first-child:before {
  border: none;
}
.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.arrow-steps .step span {
  position: relative;
}
.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
  color: #0c2b44;
}
.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}
.arrow-steps .step.current {
  color: #000;
  /* background-color: #032B43; */
  border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #9461fd, #ff53cd, #2dd9fe) 1;
}
.arrow-steps .step.current:after {
  border-left: 17px solid #2dd9fe;
   
}
@media (max-width: 765px) {
  .arrow-steps .step {
    min-width: 35px;
  }
 
  
}

.table-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.ant-table {
  flex: 1;
  overflow: auto;
}


/* Define a class for the table container */
.table-container {
  width: 100%; /* Make the table container take the full width of its parent */

}
  /* Define the initial width for the column */
  .ant-table-thead th:first-child, .ant-table-tbody td:first-child {
    max-width: 350px;
  }

  /* Use media queries to adjust the column width based on screen size */
  @media (max-width: 768px) {
    .ant-table-thead th:first-child, .ant-table-tbody td:first-child {
      max-width: 200px; /* Adjust the width for smaller screens */
    }
    .ant-tour {
      z-index: 1070;
      display: block;
      visibility: visible;
      width: 95%;
    }
  }

  @media (max-width: 480px) {
    .ant-table-thead th:first-child, .ant-table-tbody td:first-child {
      max-width: 350px; /* Adjust the width for even smaller screens */
    }
    .carousel-control {  
      background-color: transparent;
      border: 2px solid;
      border-radius: 4px;
      color: #000;
      background: white;
      cursor: pointer;
      height: 22px;
      margin: 0 20px;
      position: relative;
      transform: scale(1.5);
      transition: transform 0.5s ease-out;
      width: 22px;
    }

    .ant-tour {
      z-index: 1070;
      display: block;
      visibility: visible;
      width: 95%;
    }
    
  }

   @keyframes flip {
    0% { transform: scaleX(1); }
    100% { transform: scaleX(-1); }
  }
  
  .spin-animation {
    animation: flip 3s linear infinite;
    transform-origin: center;
    height: 200px; 
  }
  

/* .ant-modal .ant-modal-content {
    position: relative;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 20px 0px 0px 0px;
} */

@import '~primereact/resources/themes/saga-blue/theme.css';
@import '~primereact/resources/primereact.min.css';


#chart-container {
  font-family: Arial;
  height: 420px;
  border: 2px dashed #aaa;
  border-radius: 5px;
  overflow: auto;
  text-align: center;
}

.orgchart { background: white; }

#github-link {
  position: fixed;
  top: 0px;
  right: 10px;
  font-size: 3em;
}
/* 
.my-chart .p-organizationchart-line-down,
.my-chart .p-organizationchart-line-left,
.my-chart .p-organizationchart-line-right {
  border-color: red;
  border-width: 2px;
} */

.checkmark {
  display: inline-block;
  margin-right: 5px;
  font-size: 12px;
  font-weight: bold;
  background-color: white;
  color: #0c2b44;
  border-radius: 50%;
  padding: 1px 4px;
}

.checkmark {
  margin-right: 5px;
}

.step-number {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: #24013f;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  margin-right: 2px;
}

.ant-tabs-tab:hover {
  color: #0c2b44 !important;
}

.ant-tabs .ant-tabs-tab-btn:active .ant-tabs-tab-remove:active {
  color: #fff;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0c2b44;
  text-shadow: 0 0 0.25px #0c2b44;
}

.ant-tabs-ink-bar {
  background: #0c2b44 !important;
}

@keyframes move-up6 {
  to {
    transform: translateY(-10px);
  }
}

@keyframes move-down1 {
  to {
    transform: translateY(10px);
  }
}

/* @layer utilities {
  .animate-move-up6 {
    animation: move-up6 2s ease-in infinite alternate-reverse;
  }

  .animate-move-down1 {
    animation: move-down1 2s ease-in infinite alternate-reverse;
  }
} */



/* Media query for screens with a maximum width of 640px (sm screens) */
/* @media screen and (max-width: 640px) {
  .tradingview-widget-container {
   overflow-x: overlay;
   width: 100% !important;
   height: fit-content;
  }
} */

#pricing-table {
  margin: 100px;
  text-align: center;
}

#pricing-table .plan {
  background: #fff;
  border: 1px solid #ddd;
  color: #333;
  padding: 20px;
  position: relative;
}

#pricing-table #most-popular {
  z-index: 2;
  top: -13px;
  padding: 30px 20px;
  border-radius: 5px;
  -moz-box-shadow: 18px 14px 7px -14px #0c2b44, -18px -14px 6px -14px #0c2b44;
  -webkit-box-shadow: 18px 14px 7px -14px #0c2b44, -18px -14px 6px -14px #0c2b44;
  box-shadow: 18px 14px 7px -14px #0c2b44, -18px -14px 6px -14px #0c2b44;
}

#pricing-table .plan:nth-child(1) {
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
}

#pricing-table .plan:nth-child(4) {
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}

/* --------------- */

#pricing-table h3 {
  font-size: 20px;
  font-weight: normal;
  padding: 20px;
  margin: -20px -20px 50px -20px;
  background-color: #eee;
  background-image: -moz-linear-gradient(#fff, #eee);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#eee)
  );
  background-image: -webkit-linear-gradient(#fff, #eee);
  background-image: -o-linear-gradient(#fff, #eee);
  background-image: -ms-linear-gradient(#fff, #eee);
  background-image: linear-gradient(#fff, #eee);
}

#pricing-table #most-popular h3 {
  background-color: #0c2b44;
  background-image: -moz-linear-gradient(#eee, #ddd);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#eee),
    to(#ddd)
  );
  background-image: -webkit-linear-gradient(#eee, #ddd);
  background-image: -o-linear-gradient(#eee, #ddd);
  background-image: -ms-linear-gradient(#eee, #ddd);
  background-image: linear-gradient(#eee, #ddd);
  margin-top: -30px;
  padding-top: 30px;
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

#pricing-table .plan:nth-child(1) h3 {
  -moz-border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  border-radius: 5px 0 0 0;
}

#pricing-table .plan:nth-child(4) h3 {
  -moz-border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  border-radius: 0 5px 0 0;
}

#pricing-table h3 span {
  display: block;
  font: bold 25px/100px Georgia, Serif;
  color: #0c2b44;
  background: #212121;
  border: 5px solid #fff;
  height: 100px;
  width: 100px;
  margin: 10px auto -65px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  -moz-box-shadow: 0 5px 20px #212121 inset, 0 3px 0 #212121 inset;
  -webkit-box-shadow: 0 5px 20px #212121 inset, 0 3px 0 #212121 inset;
  box-shadow: 0 5px 20px #212121 inset, 0 3px 0 #212121 inset;
}

/* --------------- */

#pricing-table ul {
  margin: 20px 0 0 0;
  padding: 0;
  list-style: none;
}

#pricing-table li {
  border-top: 1px solid #ddd;
  padding: 10px 0;
}

/* --------------- */

#pricing-table .signup {
  position: relative;
  padding: 8px 20px;
  margin: 20px 0 0 0;
  color: #000;
  font: bold 14px Arial, Helvetica;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  background-color: #0c2b44;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5), 0 2px 0 rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5),
    0 2px 0 rgba(0, 0, 0, 0.7);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5), 0 2px 0 rgba(0, 0, 0, 0.7);
}

#pricing-table .signup:hover {
  background-color: #000;
  color: #0c2b44;
}

#pricing-table .signup:active,
#pricing-table .signup:focus {
  background: #0c2b44;
  top: 2px;
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.7) inset;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.7) inset;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.7) inset;
}

.ant-drawer .ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 0%;
}

.sub-menu {
  display: none;
}

.sub-menu.open {
  display: block;
}

/* Add this to handle hover for the main menu item */
.menu-item:hover .sub-menu {
  display: block;
}

/* Glassmorphism Card Styles */
.glassmorphism-card {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.glassmorphism-card:hover {
  transform: scale(1.05);
}

/* Glassmorphism Card Styles END */
.nm-concave-blue-200-sm {
  background: linear-gradient(145deg, #ebebeb, #fff);
  box-shadow: 0.1em 0.1em 0.2em #556d86, -0.1em -0.1em 0.2em #032b43;
}
.nm-concave-yellow-200-sm {
  background: linear-gradient(145deg, #ebebeb, #fff);
  box-shadow: 0.1em 0.1em 0.2em #556d86, -0.1em -0.1em 0.1em #ffba08;
}
.nm-concave-lblue-200-sm {
  background: linear-gradient(145deg, #ebebeb, #fff);
  box-shadow: 0.1em 0.1em 0.2em #59c3c3, -0.1em -0.1em 0.1em #59c3c3;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: blue;
}

.apexcharts-menu-icon {
  display: none;
}

.sub-menu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.sub-menu.open {
  max-height: 1000px;
}

.ant-tabs-nav .ant-tabs-nav-list {
  width: 250px;
  display: flex;
  justify-items: center;
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  background: transparent;
  padding: 8px 0;
  padding-left: 8px;
  font-weight: bold;
}

.ant-input {
  background-color: transparent !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: opacity 0.3s;
  align-items: center;
  justify-content: center;
}

.Jainish button[aria-label="Close"] {
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 786px) {
  .mobilescroll {
    overflow-x: scroll;
  }
}

.custom-clip {
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.blur-background {
  background: linear-gradient(177deg, #9de0ff, #ffffff, #9de0ff);
  border-radius: 4px;
  padding: 15px;
  position: relative;
}

.blur_bg {
  background: rgb(231 229 229 / 85%);
  /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  top: 0;
  bottom: 0;
  font-weight: 600;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}
.blur_bg_text {
  position: absolute;
  left: 30%;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
  font-size: 35px;
}
@media screen and (max-width: 767px) {
  .blur_bg_text {
    left: 22%;
    font-size: 27px;
  }
}

.cardswitch {
  backdrop-filter: blur(9px) saturate(99%);
  -webkit-backdrop-filter: blur(9px) saturate(99%);
  background-color: rgba(17, 25, 40, 0.49);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.custom-modalnew {
  width: 853px !important; /* Change the width */
}


.ant-modal-root .ant-modal-mask {
  position: fixed;
  inset: 0;
  z-index: 1000;
  height: 100%;
      background-color: rgb(96 91 91 / 55%);

  /* background-color: rgba(255, 255, 255, 0.09); */
  pointer-events: none;
}

/* .custom-ok-button {
    background-color: blue;
    color: white; 
} */
/* .ant-modal .ant-modal-content {
	top: 20vh !important;
} */

.flex_reverse {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .flex_reverse {
    flex-direction: column-reverse;
  }
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #9461fd;
    border-color: #9461fd;
}
.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td, .ant-table-wrapper .ant-table.ant-table-small tfoot>tr>th, .ant-table-wrapper .ant-table.ant-table-small tfoot>tr>td {
    padding: 2px 8px !important;
}

@media screen and (max-width: 786px) {
	.table-newres{
		overflow-x: scroll;
	}
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #9461fd;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
    color: #9461fd;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #9461fd50;
    border-color: #9461fd50;
}

.ant-table-wrapper .ant-table-thead>tr>th,.ant-table-wrapper .ant-table-tbody>tr>th,.ant-table-wrapper .ant-table-tbody>tr>td,.ant-table-wrapper tfoot>tr>th,.ant-table-wrapper tfoot>tr>td {
    padding: 6px 16px;
}
.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab-active{
  background: #9461fd !important;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 7px;
  height: 4px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #9461fd;
}

/* Define your custom button style */
.custom-ok-button-class {
  background-color: #9461fd; /* Change the background color as desired */
  color: #fff; /* Change the text color if needed */
  border: none; /* Remove the button border if desired */
  padding: 5px 25px;
  border-radius: 25px;
  /* Add any other custom styles you want */
}


.collapse-item {
  font-size: 10px; 
}


.ant-table-wrapper table{
  box-shadow: 9px 8px 10px #b8b9be, -3px -3px 7px #fff;
  background-color: #EBEBEB !important;
}

.ant-table-wrapper table {
  border-width: 2px;
    border-style: solid;
    /* border-image: linear-gradient(to right, #9461fd, #ff53cd, #2dd9fe) 1; */
}


.ant-table-wrapper .ant-table-container table > thead > tr:first-child > * {
  
    /* border-bottom: 2px solid #4003e6; */
    background: #f1f2f6 !important;

    /* background: linear-gradient(to right, #9461fd, #9461fd, #2dd9fe); */
    color: #000 !important;
}

/* Your CSS stylesheet */
.on {
  background-color: #223e55 !important;
  color: white;
}

.off {
  background-color: #9461fd !important;
  color: white;
}


@media (max-width: 765px) {
  .arrow-steps .step {
    min-width: 35px;
  }
  .ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: opacity 0.3s;
    align-items: center;
    overflow-x: scroll;
    justify-content: center;
}
}

.tableact {
  box-shadow: -0.75em -0.75em 2.25em hsl(var(--hue),10%,30%), 0.75em 0.75em 2.25em hsl(var(--hue),5%,5%);
  /* box-shadow: -10px -10px 15px rgba(255,255,255,0.5), 10px 10px 15px rgba(70,70,70,0.15), inset -10px -10px 15px rgba(255,255,255,0.5), inset 10px 10px 15px rgba(70,70,70,0.15); */
}

.newtable{
      padding:17px 8px !important;
}



.rotate {
  animation: rotate 2s linear infinite; /* You can adjust the duration and other animation properties as needed */
}


@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.borderText{
  -webkit-text-stroke: 2px #454545;
  -webkit-text-fill-color: transparent;
  font-family: Rubik,sans-serif;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: .05em;
}


@media (max-width: 991px) {
  
.stickyexample{
  position: -webkit-fixed;
  position: fixed!important;
  top: 5rem;
  margin-bottom: 10rem;
}
}

.stickyexample{
  position: -webkit-sticky ;
  position: sticky;
  top: 5rem;
  margin-bottom: 2rem;
}

.sticky_navbar_guide{
  position: -webkit-sticky;
  position: sticky;
  top: 0rem;
}

html {
  scroll-behavior: smooth;
}

.custom-dropdown {
  max-height: 70px; /* Set the maximum height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Add this to your CSS file */
.refreshing {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.font-Square721CnBT {
  font-family: 'Square721Cn BT', sans-serif !important;
}

.main-container {
  display: flex;
}


.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: #fff;
  color: #000;
  height: 102vh;
  overflow-y: auto;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 22px;
  line-height: 0;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: black;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  
}
.link:hover {
  border-right: 4px solid #9461fd;
  background: linear-gradient(to right, #9461fd, #9461fd, #9461fd);
  /* border: 2px solid; */
  /* border-image: linear-gradient(to right, #9461fd, #ff53cd, #FAFBFD) 1; */
  /* color: #9461fd; */
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  color: #fff;
}
.active {
  border-right: 4px solid #9461fd;
  background: linear-gradient(to right, #9461fd, #9461fd,#9461fd);
  /* border: 2px solid; */
  /* background-color: linear-gradient(to right, #9461fd, #4003e6, #FAFBFD) 1; */
  color: #fff;
}

/* .active .icon {
  background: #FAFBFD;
  padding: 2px 4px;
  border-radius: 50%;
  box-shadow: -3px 3px 20px #b8b9be, -3px -3px 7px #b3a8a8;
 
} */

.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: black;
align-items: center;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  align-items: center;
  gap: 10px;
  
}
.menu_container {
  display: flex;
  
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  align-items: center;
}



/* Matrix.css */

.matrix-container {
  text-align: center;
  margin: 20px;
}

.matrix {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.matrix-row {
  display: flex;
  justify-content: center;
}

.matrix-cell {
  border: 1px solid #ccc;
  padding: 10px;
  width: 80px;
  text-align: center;
}

.Textplaceholder::placeholder {
  font-weight: bold;
  font-size: 14px;
}


/* -------Timeline------ */


#timeline {
  list-style: none;
  margin: 50px 0 30px 10px;
  padding-left: 35px;
  /* border-left: 8px solid #eee9dc; */
  border-left-width: 8px;
    border-style: solid;
    border-image: linear-gradient(to right, #9461fd, #ff53cd, #2dd9fe) 1;
}
#timeline li {
  margin: 40px 0;
  position: relative;
}
#timeline p {
  margin: 0 0 15px;
}

.date {
  margin-top: -10px;
  top: 50%;
  left: -158px;
  font-size: 0.95em;
  line-height: 20px;
  position: absolute;
}

.circle {
  margin-top: -10px;
  top: 50%;
  left: -44px;
  width: 10px;
  height: 10px;
  background: #000;
  border: 5px solid #2dd9fe;
  border-radius: 50%;
  display: block;
  position: absolute;
}

.content {
  height: auto;
  padding: 50px 20px 0;
  border-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-radius: 0.5em;
  position: relative;
}
.content:before, .content:after {
  content: "";
  width: 0;
  height: 0;
  border: solid transparent;
  position: absolute;
  pointer-events: none;
  right: 100%;
}
.content:before {
  border-right-color: inherit;
  border-width: 20px;
  top: 50%;
  margin-top: -20px;
}
.content:after {
  border-right-color: #000;
  border-width: 17px;
  top: 50%;
  margin-top: -17px;
}
.content p {
  max-height: 0;
  color: transparent;
  text-align: justify;
  word-break: break-word;
  hyphens: auto;
  overflow: hidden;
}

.labeltime {
  font-size: 18px;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  top: 13px;
  transition: transform 0.2s linear;
}

/* .radio {
  display: none;
}

.radio:checked + .relative .labeltime {
  cursor: auto;
  transform: translateX(42px);
}
.radio:checked + .relative .circle {
  background: #2dd9fe;
}
.radio:checked ~ .content {
  max-height: 280px;
  border-color: #eee9dc;
  margin-right: 20px;
  transform: translateX(20px);
  transition: max-height 0.4s linear, border-color 0.5s linear, transform 0.2s linear;
}
.radio:checked ~ .content p {
  max-height: 200px;
  color: #eee9dc;
  transition: color 0.3s linear 0.3s;
} */

.radio {
  display: none;
}

.radio:checked + .relative .labeltime {
  cursor: auto;
  transform: translateX(42px);
}

.radio:checked + .relative .circle {
  background: #2dd9fe;
}

.radio:checked ~ .content {
  height: auto;
  border-color: #eee9dc;
  margin-right: 20px;
  transform: translateX(20px);
  transition: height 0.4s linear, border-color 0.5s linear, transform 0.2s linear;
}
/* 
.table-container {
  overflow-y: auto; 
  max-height: 200px;
} */

.radio:checked ~ .content p {
  max-height: 200px;
  color: #eee9dc;
  transition: color 0.3s linear 0.3s;
}


/* -------------------------------------
 * mobile phones (vertical version only)
 * ------------------------------------- */
@media screen and (max-width: 767px) {
  #timeline {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }
  #timeline li {
    margin: 50px 0;
  }

  .labeltime {
    width: 85%;
    font-size: 1.1em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    transform: translateX(18px);
  }

  .content {
    padding-top: 45px;
    border-color: #eee9dc;
  }
  .content:before, .content:after {
    border: solid transparent;
    bottom: 100%;
  }
  .content:before {
    border-bottom-color: inherit;
    border-width: 17px;
    top: -16px;
    left: 50px;
    margin-left: -17px;
  }
  .content:after {
    border-bottom-color: #48b379;
    border-width: 20px;
    top: -20px;
    left: 50px;
    margin-left: -20px;
  }
  .content p {
    font-size: 0.9em;
    line-height: 1.4;
  }

  .circle, .date {
    display: none;
  }
}

.MuiCardContent-root:last-child {
    padding-bottom: 5px !important;
}


.box {
  width: auto;
  height: 60vmin;
  display: grid;
  place-content: center;
  color: white;
  text-shadow: 0 1px 0 #000;
  --border-angle: 0turn;
  --main-bg: conic-gradient(
      from var(--border-angle),
      rgb(255, 255, 255),
      rgb(255, 255, 255) 5%,
      rgb(255, 255, 255) 60%,
      rgb(255, 255, 255) 95%
    );
  border: solid 3px transparent;
  border-radius: 2em;
  --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, #f03 99%, transparent);
  background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
  background-position: center center;
  -webkit-animation: bg-spin 3s linear infinite;
          animation: bg-spin 3s linear infinite;
}
@-webkit-keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
.box:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

/* balkan drawer  */
.boc-edit-form{
  display: none !important;
}

.boc-light{
  height: 100vh;
}

.card-so-wrapper{
  width: 220px;
  height: 70px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.78);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  position: absolute;
  left: -110px;
  display: flex;
}

.MuiCardContent-root {
    padding: 2px !important;
}

.pagination a{
  background-color: green !important;
}


#chart-container {
  font-family: Arial;
  height: 420px;
  border: 2px dashed #aaa;
  border-radius: 5px;
  overflow: auto;
  text-align: center;
}

.orgchart {
  background: #fff; 
}
.orgchart td.left, .orgchart td.right, .orgchart td.top {
  border-color: #aaa;
}
.orgchart td>.down {
  background-color: #aaa;
}
.orgchart .middle-level .title {
  background-color: #006699;
}
.orgchart .middle-level .content {
  border-color: #006699;
}
.orgchart .product-dept .title {
  background-color: #009933;
}
.orgchart .product-dept .content {
  border-color: #009933;
}
.orgchart .rd-dept .title {
  background-color: #993366;
}
.orgchart .rd-dept .content {
  border-color: #993366;
}
.orgchart .pipeline1 .title {
  background-color: #996633;
}
.orgchart .pipeline1 .content {
  border-color: #996633;
}
.orgchart .frontend1 .title {
  background-color: #cc0066;
}
.orgchart .frontend1 .content {
  border-color: #cc0066;
}

#github-link {
  position: fixed;
  top: 0px;
  right: 10px;
  font-size: 3em;
}

.ant-input-group .ant-input-group-addon:first-child {
  padding: 12px 16px !important;
}

.ant-input-affix-wrapper{
  padding: 8px 16px !important;
}

.node-label {
  background-color: yellow;
  border: 2px solid black;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}


